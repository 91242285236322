.conversation_history {

    &-wrapper {
        &.MuiPaper-root {
            border-radius: 10px;
            box-shadow: none;
            overflow: hidden;
            border: 1px solid #22A6DE;
            height: 100%;
        }
    }

    &-header {
        &.MuiList-root {
            background-color: #8B8B8B;
            padding: 0;
            height: 85px;
            display: flex;
            align-items: center;
            @media (max-width: 1400px) {
                height: 82px;
            }
            .MuiListItemIcon-root {
                margin-right: 15px;
                .MuiAvatar-root {
                    width: 64px;
                    height: 64px;
                }
            }
            .MuiListItemText-root {
                .MuiListItemText {
                    &-primary {
                        font-size: 24px;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media (max-width: 1400px) {
                            font-size: 22px;
                        }
                        @media (max-width: 768px) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    &-list {
        &.MuiList-root {
            padding: 0;
            max-height:700px;
            overflow-y: auto;
            &::-webkit-scrollbar-track {
                background-color: transparent
            }
        
            &::-webkit-scrollbar {
                width: 8px;
                background-color: transparent
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: #E0E0E0;
                border-radius: 10px;
            }
            @media (max-width: 1200px) {
                max-height:405px;
            }
            .MuiListItem-root {
                padding: 16px;
                text-align: center;
                @media (max-width: 768px) {
                    padding: 15px 12px;
                }

                &:hover,
                &.active {
                    background: none;

                    .MuiListItemText-root {
                        .MuiListItemText {
                            &-primary {
                                color: #225FA0;
                            }

                            &-secondary {
                                color: #225FA0;

                            }
                        }
                    }
                }

                &:nth-child(odd) {
                    background-color: #F5F5F5;
                    border: 1px solid #bababa66;
                    border-width: 1px 0;

                    &:last-child {
                        border-bottom: 0;
                    }
                }

                .MuiListItemText-root {
                    margin: 0;

                    .MuiListItemText {
                        &-primary {
                            font-size: 17px;
                            color: #000;
                            font-weight: bold;
                            display: inline-block;
                            margin-right: 8px;
                            @media (max-width: 1400px) {
                                font-size: 15px;
                            }
                            @media (max-width: 768px) {
                                font-size: 14px;
                            }
                        }

                        &-secondary {
                            font-size: 17px;
                            color: #000;
                            display: inline-block;
                            @media (max-width: 1400px) {
                                font-size: 15px;
                            }
                            @media (max-width: 768px) {
                                font-size: 14px;
                            }
                        }

                    }
                }
            }
        }
    }
}
.chat_box {
    &-wrapper {
        .MuiListItem-root{
            padding-top:6px;
            padding-bottom:6px;
        }
        &.MuiPaper-root {
            border-radius: 10px;
            box-shadow: none;
            overflow: hidden;
            
        }
    }

    &-header {
        &.MuiList-root {
            background-color: #8B8B8B;
            padding: 0;
            .MuiListItemIcon-root {
                margin-right: 15px;

                @media (max-width: 576px) {
                    margin-right: 5px;
                    min-width: auto;
                }

                .MuiAvatar-root {
                    width: 64px;
                    height: 64px;

                    @media (max-width: 576px) {
                        width: 44px;
                        height: 44px;
                    }
                }
            }

            .MuiListItemText-root {


                .MuiListItemText {

                    &-primary {
                        font-size: 24px;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        @media (max-width: 1400px) {
                            font-size: 22px;
                        }
                        @media (max-width: 768px) {
                            font-size: 18px;
                        }
                        @media (max-width: 576px) {
                            font-size: 16px;
                        }
                        .MuiButtonBase-root {
                            &.edit-iconButton {
                                font-size: 18px;
                                color: #fff;
                                margin-left: 2px;

                                @media (max-width: 576px) {
                                    font-size: 14px;
                                    padding: 5px;
                                }
                            }
                        }
                    }

                    &-secondary {
                        font-size: 17px;
                        color: #e3e3e3;
                        font-weight: 500;

                        @media (max-width: 576px) {
                            font-size: 14px;

                        }
                    }
                }
            }
        }
    }

    &-body {
        background-color: #F5F9FF;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &-messagesWrap {
            overflow: auto;
            padding: 0 28px;
            height: 600px;
            &::-webkit-scrollbar-track {
                background-color: transparent
            }
        
            &::-webkit-scrollbar {
                width: 8px;
                background-color: transparent
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: #E0E0E0;
                border-radius: 10px;
            }
            @media (max-width:767px) {
                padding: 0 15px;
                height: 500px;
            }
        }

        &-messagesList {
            &.MuiList-root {
                padding: 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;

                .MuiListItem-root {
                    align-items: flex-end;
                    padding: 0;

                    .MuiAvatar-root {
                        width: 44px;
                        height: 44px;

                        @media (max-width:768px) {
                            width: 34px;
                            height: 34px;
                        }
                    }

                    &[align="left"] {
                        flex-direction: row-reverse;

                        .MuiAvatar-root {
                            margin-right: 14px;

                            @media (max-width:768px) {
                                margin-right: 8px;
                            }

                            @media (max-width: 576px) {
                                margin-right: 5px;
                            }
                        }

                        .MuiListItemText-root {
                            text-align: left;
                            flex-direction: row-reverse;

                            &.hasReply {
                                .MuiListItemText {
                                    &-primary {
                                        margin-bottom: 6px;
                                    }
                                }
                            }

                            .MuiListItemText {
                                &.hasReply {
                                    &-primary {
                                        margin-bottom: 6px;
                                    }
                                }

                                &-primary {
                                    display: inline-block;
                                    background-color: #E8E6F2;
                                    border-radius: 0 11px 11px 0;
                                    padding: 10px;
                                    color: #000;
                                    font-size: 16px;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 500;
                                    width: 100%;
                                    max-width: calc(50% - 30px);
                                    text-align: left;
                                    @media (max-width: 1600px) {
                                        font-size: 15px;
                                    }
                                    @media (max-width: 1400px) {
                                        font-size: 14px;
                                    }
                                    @media (max-width:768px) {
                                        max-width: calc(100% - 80px);
                                        font-size: 13px;

                                    }
                                    @media (max-width:576px) {
                                        max-width: calc(100% - 30px);
                                        font-size: 12px;
                                    }
                                    .replyFor {
                                        border-bottom: 1px solid #cbcbcb;
                                        padding-bottom: 8px;
                                        margin-bottom: 10px;
                                        font-weight: normal;
                                        font-style: italic;
                                        color: #8a8a8a;
                                        font-size: 15px;
                                        display: flex;
                                        align-items: flex-start;
                                        @media (max-width: 768px) {
                                            font-size: 13px;
                                        }
                                        @media (max-width: 576px) {
                                            font-size: 12px;
                                        }

                                        svg {
                                            margin-right: 15px;
                                            margin-top: 3px;
                                        }
                                    }
                                }

                                &-secondary {
                                    font-weight: 500;
                                    font-size: 12px;
                                    margin-top: 15px;
                                    font-family: 'Roboto', sans-serif;
                                }

                            }

                            &:first-child {
                                .MuiListItemText {
                                    &-primary {
                                        border-radius: 11px 11px 11px 0;
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                        }
                    }

                    &[align="right"] {
                        .MuiAvatar-root {
                            margin-left: 14px;

                            @media (max-width:768px) {
                                margin-left: 8px;
                            }

                            @media (max-width: 576px) {
                                margin-left: 5px;
                            }
                        }

                        .MuiListItemText-root {
                            text-align: right;

                            .MuiListItemText {
                                &-primary {
                                    display: inline-block;
                                    background-color: #CBDFFF;
                                    border-radius: 11px 0 0 11px;
                                    padding: 10px;
                                    color: #000;
                                    font-size: 16px;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 500;
                                    width: 100%;
                                    max-width: calc(50% - 30px);
                                    text-align: left;
                                    @media (max-width: 1600px) {
                                        font-size: 15px;
                                    }
                                    @media (max-width: 1400px) {
                                        font-size: 14px;
                                    }
                                    @media (max-width:768px) {
                                        max-width: calc(100% - 80px);
                                        font-size: 13px;

                                    }
                                    @media (max-width:576px) {
                                        max-width: calc(100% - 30px);
                                        font-size: 12px;
                                    }

                                    .replyFor {
                                        border-bottom: 1px solid #cbcbcb;
                                        padding-bottom: 8px;
                                        margin-bottom: 10px;
                                        font-weight: normal;
                                        font-style: italic;
                                        color: #8a8a8a;
                                        font-size: 15px;
                                        display: flex;
                                        align-items: flex-start;
                                        @media (max-width: 768px) {
                                            font-size: 13px;
                                        }
                                        @media (max-width: 576px) {
                                            font-size: 12px;
                                        }

                                        svg {
                                            margin-right: 15px;
                                            margin-top: 3px;
                                        }
                                    }
                                }

                                &-secondary {
                                    font-weight: 500;
                                    font-size: 12px;
                                    font-family: 'Roboto', sans-serif;
                                }
                            }

                            &:first-child {
                                .MuiListItemText {
                                    &-primary {
                                        border-radius: 11px 11px 0 11px;
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                        }
                    }
                }
            }

        }

        &-inputWrapper {
            align-items: center;
            padding: 21px 28px;
            @media (max-width:767px) {
                padding: 15px 15px;
            }

            .MuiInputBase-root {
                padding-left: 5px;
                border-radius: 7px;
                margin-right: 14px;
                background: #fff;
                border: 1px solid #E1E1E1;

                @media (max-width: 576px) {
                    margin-right: 5px;

                }

                .MuiInputBase-input {
                    @media (max-width: 576px) {
                        padding: 10.5px 0;
                    }
                }

                .MuiInputAdornment {
                    &-positionStart {
                        .MuiButtonBase-root {
                            margin-right: 5px;

                            &::after {
                                content: '';
                                width: 1px;
                                position: absolute;
                                right: -5px;
                                top: 0;
                                bottom: 0;
                                background: #cbcbcb;
                            }
                        }
                    }
                }
            }

            .MuiButtonBase-root {
                &.MuiFab-root {
                    min-width: 54px;
                    width: 54px;
                    height: 54px;
                    background-color: #707070;
                    color: #fff;

                    @media (max-width: 576px) {
                        min-width: 44px;
                        width: 44px;
                        height: 44px;
                    }

                }
            }
        }

    }
}
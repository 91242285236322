.profileInfoWrap{
    .employeeName{
        max-width: 130px;
        line-height:1.3;
        margin: 15px auto 0px;
    }
    .jobTitle{
        font-size: 16px;
        margin-bottom: 0px;
    }
}

.login {
  &-wrapper {
    &.MuiCard-root {
      padding: 50px 15px;
      box-shadow: none;
      background: #6c6c6c;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      border-radius: 0px;
      overflow: auto;
    }
    &-inner {
      max-width: 530px;
      margin: 0 auto;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      .MuiCardHeader {
        &-root {
          text-align: center;
          padding: 22px 16px;
          background-color: #065492;
        }
        &-title {
          font-size: 30px;
          font-weight: bold;
          color: #fff;
          @media (max-width: 1400px) {
            font-size: 25px;
          }
        }
      }
      .MuiCardContent-root {
        background-color: #fff;
        padding: 25px 40px 30px;
        @media (max-width: 575px) {
          padding: 20px;
        }
      }
    }
  }
  &-formwrap {
    label {
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 18px;
    }
    .MuiFormControl {
      &-root {
        & .MuiOutlinedInput-root {
          & .MuiInputAdornment-positionStart ~ input {
            padding-left: 4px;
            border-radius: 0;

          }
        }
      }
    }
    // .rememberMe-wrap {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   .MuiFormControlLabel-root {
    //     margin-bottom: 0;
    //     margin-right: 0;
    //     .MuiFormControlLabel-label {
    //       font-weight: 600;
    //       margin: 0;
    //       &:hover {
    //         color: #1c5492;
    //       }
    //     }
    //   }
    //   .MuiTypography-root {
    //     &.MuiLink-root {
    //       margin: 0;
    //       font-weight: 600;
    //       color: #000;
    //       text-decoration-color: #000;
    //       &:hover {
    //         color: #1c5492;
    //       }
    //     }
    //   }
    // }
    .MuiFormHelperText-root {
      margin-left: 0;
      margin-right: 0;
    }
    .MuiButtonBase-root.login-btn {
      &.login-btn {
        font-size: 16px;
        box-shadow: none;
        border-radius: 50px;
        padding: 13px 10px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}

.employee-grid-wrapper{
    .MuiCardHeader {
        &-root {
          text-align: center;
          border-bottom: 1px solid #e2e2e2;
          .MuiTypography-root {
            font-size: 24px;
            @media (max-width:1600px) {
                font-size: 20px;
            }
            @media (max-width:1400px) {
                font-size: 20px;
            }
            @media (max-width:991px) {
                font-size: 18px;
            }
          }
        }
      }
      .employee{
        &-List{
            padding:0px 30px;
            max-height: 732px;
            overflow: auto;
            margin-top: 0;
            @media (max-width:1200px) {
                max-height: 532px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent
            }
        
            &::-webkit-scrollbar {
                width: 8px;
                background-color: transparent
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: #E0E0E0;
                border-radius: 10px;
            }
            @media (max-width:991px) {
                padding:0px 20px;
            }
            .employeeWrap{
                .employeeCard{
                    border: 1px solid #22A6DE;
                    padding:15px 10px 18px;
                    border-radius: 10px;
                    text-align: center;
                    height: 100%;
                    .employeeName{
                        font-size: 18px;
                        margin-top: 12px;
                        margin-bottom: 0;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        max-width: 100%;
                    }
                    .previousInfo{
                        font-size: 12px;
                        color: #868686;
                        display: block;
                        margin-top: 5px;
                    }
                    .profileInfoWrap {
                        .MuiAvatar-root {
                            width: 110px;
                            height: 110px;
                            // padding: 8px;
                            margin: 0 auto;
                            // background: linear-gradient(180deg, #22a6de, #2283bf, #225fa0);
                            border: 8px solid #E5E5E5;
                            font-size: 50px;
                            font-weight: 600;
                        }
                        .MuiTypography {
                            &-h6 {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                padding-bottom: 8px;
                            }
                        }
                    }
                    .profileBtnsWrap {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 10px;
                        .MuiButtonBase-root {
                            &:not(:first-child) {
                                margin-top:10px;
                            }
                        }
                        .btn-primary{
                            font-size: 12px;
                            height: 31px;
                            min-height: 32px;
                            padding:5px;
                            width: 100%;
                            min-width: auto;
                            box-shadow: none;
                            max-width: 145px;
                        }
                        .btn-border{
                            font-size: 12px;
                            height: 31px;
                            padding:5px;
                            width: 100%;
                            max-width: 145px;
                            box-shadow: none;
                            svg{
                                margin-right: 5px;
                                height: 16px;
                                path{
                                    fill: #ff3e3e;
                                }
                            }
            
                        }
                    }
                    // .employee-range-slider{
                    .MuiSlider-root{
                        height: 15px;
                        padding: 0;
                        margin-top: 5px;
                        margin-bottom: 24px;
                        max-width: 128px;
                        background: transparent linear-gradient(90deg, #225FA0 0%, #22A1DA 50%, #22A6DE 100%) 0% 0% no-repeat padding-box;
                        .MuiSlider-rail{
                            opacity: 0;
                        }
                        .MuiSlider-track{
                            opacity: 0;
                        }
                        .MuiSlider-thumb{
                            background-color: #fff;
                            border: 1px solid #225FA0;
                            width: 22px;
                            height: 22px;
                        }
                        .MuiSlider-valueLabel{
                            padding: 0;
                            background:transparent;
                            top: 50%;
                            transform: translateY(-50%);
                            .MuiSlider-valueLabelLabel{
                                color: #000;
                                font-size: 14px;
                                font-weight: 600;
        
                            }
                        }
                    }
                }
                .employeeCard.error{
                    border: 1px solid #FF3E3E !important;
                    box-shadow: 0px 0px 0px 2px #ff3e3e;
                    // .employee-range-slider{
                        .MuiSlider-root{
                        .MuiSlider-valueLabel{
                            .MuiSlider-valueLabelLabel{
                                color: #ff3e3e;
                            }
                        }
                    }
                }
            }

        }

      }

}

/*******************/
.employee-List{
    .employeeWrap{
        &:nth-child(5n+1){
            .MuiAvatar-root{
                background: #225FA0;
            }
        }
        &:nth-child(5n+2){
            .MuiAvatar-root{
                background: #C74F11;
            }
        }
       
        &:nth-child(5n+3){
            .MuiAvatar-root{
                background: #267912;
            }
        }
        &:nth-child(5n+4){
            .MuiAvatar-root{
                background: #6B378B;
            }
        }
        &:nth-child(5n+5){
            .MuiAvatar-root{
                background: #801E1E;
            }
        }
       
       
    }
}

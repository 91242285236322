/********************Global Button css start**********************/
@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica/Helvetica-BoldOblique.eot");
  src: url("assets/fonts/Helvetica/Helvetica-BoldOblique.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/Helvetica/Helvetica-BoldOblique.woff2") format("woff2"),
    url("assets/fonts/Helvetica/Helvetica-BoldOblique.woff") format("woff"),
    url("assets/fonts/Helvetica/Helvetica-BoldOblique.ttf") format("truetype"),
    url("assets/fonts/Helvetica/Helvetica-BoldOblique.svg#Helvetica-BoldOblique")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica/Helvetica-Bold.eot");
  src: url("assets/fonts/Helvetica/Helvetica-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Helvetica/Helvetica-Bold.woff2") format("woff2"),
    url("assets/fonts/Helvetica/Helvetica-Bold.woff") format("woff"),
    url("assets/fonts/Helvetica/Helvetica-Bold.ttf") format("truetype"),
    url("assets/fonts/Helvetica/Helvetica-Bold.svg#Helvetica-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica/Helvetica.eot");
  src: url("assets/fonts/Helvetica/Helvetica.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Helvetica/Helvetica.woff2") format("woff2"),
    url("assets/fonts/Helvetica/Helvetica.woff") format("woff"),
    url("assets/fonts/Helvetica/Helvetica.ttf") format("truetype"),
    url("assets/fonts/Helvetica/Helvetica.svg#Helvetica") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica/Helvetica-Light.eot");
  src: url("assets/fonts/Helvetica/Helvetica-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Helvetica/Helvetica-Light.woff2") format("woff2"),
    url("assets/fonts/Helvetica/Helvetica-Light.woff") format("woff"),
    url("assets/fonts/Helvetica/Helvetica-Light.ttf") format("truetype"),
    url("assets/fonts/Helvetica/Helvetica-Light.svg#Helvetica-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica/Helvetica-Oblique.eot");
  src: url("assets/fonts/Helvetica/Helvetica-Oblique.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Helvetica/Helvetica-Oblique.woff2") format("woff2"),
    url("assets/fonts/Helvetica/Helvetica-Oblique.woff") format("woff"),
    url("assets/fonts/Helvetica/Helvetica-Oblique.ttf") format("truetype"),
    url("assets/fonts/Helvetica/Helvetica-Oblique.svg#Helvetica-Oblique") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


*, *:before, *:after {
	box-sizing: border-box;
}

body{
  font-family: "Helvetica";
}

.rootWrapper{
  padding: 0px 25px 25px;
  @media screen and (max-width: 1200px) {
    padding: 0px 15px 25px;
  }
  .rootContainer{
    background-color: #FFFFFF;
    border-radius: 0px 0px 10px 10px;
    padding: 40px;
    box-shadow: 0px 0px 6px #0000001A;
    @media screen and (max-width: 1600px) {
      padding: 30px;
    }
    @media screen and (max-width: 1200px) {
      padding: 25px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }
}
.breadcrumb-wrap{
  background-color: #225FA0;
  color: #fff;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 40px;
  border-radius: 10px 10px 0px 0px;
  @media screen and (max-width: 1600px) {
    padding: 15px 30px;
  }
  @media screen and (max-width: 1200px) {
    padding: 15px 25px;
  }
  @media screen and (max-width: 991px) {
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    padding: 15px 20px;
  }
  .breadcrumb-title{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    padding-left: 30px;
    line-height: 1.3;
    position: relative;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    svg{
      margin-right:5px;
      height: 20px;
      vertical-align: bottom;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .right-menu{
    flex: 1;
    padding-left: 15px;
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      justify-content: flex-end;
      padding: 4px 0px;
      li {
          margin-left: 50px;
          padding: 10px 0px;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;
          position: relative;
          &::before{
            content: "";
            width: 100%;
            height: 3px;
            background: #fff;
            position: absolute;
            left: 0;
            top: 100%;
            opacity: 0;
          }
          &:first-child{
            margin-left: 0;
          }
          &:hover{
            &::before{
              opacity: 0.5;
            }
            
          }
          @media screen and (max-width: 1400px) {
            font-size: 15px;
            margin-left: 30px;
          }
          @media screen and (max-width: 991px) {
            font-size: 14px;
            margin-left: 20px;
          }
      }
      li.activeMenu{
        &::before{
          opacity: 1;
        }
      }

    }
  } 
}


.MuiButton-root {
  &.btn-primary {
    // background-color: #225FA0;
    color: #fff;
    min-width: 174px;
    min-height: 45px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 25px;
    border: none;
    font-size: 14px;
    border-radius: 25px;
    text-transform: capitalize;
  }
  &.btn-text {
    color: #000000;
    background-color: transparent;
    text-decoration: underline;
    text-transform: capitalize;
    min-height: 45px;
    font-weight: bold;
    padding: 10px 25px;
    border-radius: 25px;
    &:hover {
      color: #225fa0;
      background-color: transparent;
      text-decoration: underline;
    }
  }
  &.btn-border {
    color: #000000;
    background-color: transparent;
    text-transform: capitalize;
    height: 45px;
    font-weight: bold;
    padding: 10px 25px;
    border-radius: 25px;
    border: 1px solid #707070;
    &:hover {
      background-color: transparent;
      border: 2px solid #707070;
    }
  }
  &.btn-white {
    text-transform: capitalize;
    height: 45px;
    font-weight: bold;
    padding: 10px 35px;
    border-radius: 25px;
  }
}

/********************Global Button css End**********************/

/********************Global Input css start**********************/
.MuiFormControl-root {
  .MuiInputBase-root {
    margin-top: 1px;
    input {
      padding: 13.5px 15px;
    }
  }
}

.font14 {
  font-size: 14px;
}
/*********************************/

.topRow {
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-gap: 35px;
    @media(max-width: 1499px) {
      grid-gap: 25px;
    }
    @media(max-width: 1199px) {
      grid-template-columns: 230px 1fr;
    }
    @media(max-width: 991px) {
      grid-template-columns: 1fr;
    }
    .employee_card-wrapper {
      margin-bottom: 30px;
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
    .topLeft {
      margin-top: -70px;
      @media (max-width:1400px) {
        margin-top: -60px;
      }
      @media (max-width:991px) {
        margin-top: 0px;
      }
    }
    .topRight {
      display: grid;
      grid-gap: 25px;
      .topRight-inner {
        display: flex;
        align-items: flex-start;
        grid-gap: 35px;
        @media(max-width: 1499px) {
          grid-gap: 25px;
        }
        @media(max-width: 1199px){
          flex-wrap: wrap;
        }
        .pulse_rating-wrapper{
          max-width: 50%;
          flex: 1 1 100%;
          @media(max-width: 1200px){
            max-width: 100%;
          }
        }
        .pulse_chart-wrapper {
          max-width: 50%;
          flex: 1 1 100%;
          @media(max-width: 1200px) {
            max-width: 100%;
          }
        }
        .change_tracking-wrapper{
          max-width: 50%;
          flex: 1 1 100%;
          @media(max-width: 1200px){
            max-width: 100%;
          }
        }
        .engagement_pillars-wrapper {
          margin-top:18px;
          @media (max-width: 1199px) {
            margin-top: 0;
          }
        }
      }
    }

}
.bottomRow {
  @media (max-width: 991px) {
    margin-top: 30px;
  }
  .engagement_pillars{
    &-wrapper {
      display: grid;
      grid-template-columns: 230px 1fr;
      grid-gap: 35px;
      align-items: flex-start;
      padding-top: 30px;
      @media(max-width: 1199px) {
        grid-template-columns: 1fr;
      }
      @media(max-width: 991px) {
        grid-template-columns: 1fr;
      }
    }

    &-targetBox{
      grid-row: span 2;
      @media(max-width: 1199px) {
        grid-row: auto;
      }
      @media(max-width: 991px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
.managerChatPageContainer {
  display: grid;
  grid-template-columns: 230px 1fr 340px;
  grid-gap: 30px;
  @media (max-width: 1399px) {
    grid-template-columns: 230px 1fr 250px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  & .MuiGrid-item {
    &.profileBox{
      margin-top: -70px;
      @media (max-width:1400px) {
        margin-top: -60px;
      }
      @media (max-width:991px) {
        margin-top: 0px;
      }
    }
    &.chatBox {
      @media (max-width: 1199px) {
        grid-column: span 3;
      }
      @media (max-width: 767px) {
        grid-column: auto;
        grid-row: auto;
      }
    }
    &.historyBox {
      @media (max-width: 1199px) {
        grid-column: span 2/4;
        grid-row: 1/2;
      }
      @media (max-width: 767px) {
        grid-column: auto;
        grid-row: auto;
      }
    }
  }
}

/********************Global Input css End**********************/

// $gb: #f2eee8;
// $wh: #ffffff;
// $dark: #383a41;
// $red: #eb6d6d;
// $blue: #5e7fdc;
// $break: 400px;

// .errorPageWraper {
// 	margin: 0;
// 	padding: 0;
// 	width: 100%;
// 	min-height: 100vh;
// 	background-color: $gb;
// 	font-family: 'Open Sans';
// }


// .errorPageWraper {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     .face {
//         width: 300px;
//         height: 300px;
//         border: 4px solid $dark;
//         border-radius: 10px;
//         background-color: $wh;
//         margin: 0 auto;
//         // margin-top: 100px;
        
//         @media screen and (max-width: $break) {
//             margin-top: 40px;
//             transform: scale(.8);
//         }
        
//         .band {
//             width: 350px;
//             height: 27px;
//             border: 4px solid $dark;
//             border-radius: 5px;
//             margin-left: -25px;
//             margin-top: 50px;
            
//             .red {
//                 height: calc(100% / 3);
//                 width: 100%;
//                 background-color: $red;
//             }
            
//             .white {
//                 height: calc(100% / 3);
//                 width: 100%;
//                 background-color: $wh;
//             }
            
//             .blue {
//                 height: calc(100% / 3);
//                 width: 100%;
//                 background-color: $blue;
//             }
            
//             &:before {
//                 content: "";
//                 display: inline-block;
//                 height: 27px;
//                 width: 30px;
//                 background-color: rgba($wh,0.3);
//                 position: absolute;	
//                 z-index: 999;
//             }
            
//             &:after {
//                 content: "";
//                 display: inline-block;
//                 height: 27px;
//                 width: 30px;
//                 background-color: rgba($dark,0.3);
//                 position: absolute;	
//                 z-index: 999;
//                 right: 0;
//                 margin-top: -27px;
//             }
//         }
        
//         .eyes {
//             width: 100px;
//             margin: 0 auto;
//             margin-top: 40px;
            
//             &:before {
//                 content: "";
//                 display: inline-block;
//                 width: 30px;
//                 height: 15px;
//                 border: 7px solid $dark;
//                 margin-right: 20px; 
//                 border-top-left-radius: 22px;
//                 border-top-right-radius: 22px;
//                 border-bottom: 0;
//             }
            
//             &:after {
//                 content: "";
//                 display: inline-block;
//                 width: 30px;
//                 height: 15px;
//                 border: 7px solid $dark;
//                 margin-left: 20px;
//                 border-top-left-radius: 22px;
//                 border-top-right-radius: 22px;
//                 border-bottom: 0;
//             }
//         }
        
//         .dimples {
//             width: 180px;
//             margin: 0 auto;
//             margin-top: 15px;
            
//             &:before {
//                 content: "";
//                 display: inline-block;
//                 width: 10px;
//                 height: 10px;
//                 margin-right: 80px; 
//                 border-radius: 50%;
//                 background-color: rgba($red,0.4);
//             }
            
//             &:after {
//                 content: "";
//                 display: inline-block;
//                 width: 10px;
//                 height: 10px;
//                 margin-left: 80px;
//                 border-radius: 50%;
//                 background-color: rgba($red,0.4);
//             }
//         }
        
//         .mouth {
//             width: 40px;
//             height: 5px;
//             border-radius: 5px;
//             background-color: $dark;
//             margin: 0 auto;
//             margin-top: 25px;
//         }
//     }

//     h1 {
//         font-weight: 800;
//         color: $dark;
//         text-align: center;
//         font-size: 2.5em;
//         padding-top: 20px;
        
//         @media screen and (max-width: $break) {
//             padding-left: 20px;
//             padding-right: 20px;
//             font-size: 2em;
//         }
//     }

//     .btn {
//         font-family: "Open Sans";
//         font-weight: 400;
//         padding: 20px;
//         background-color: rgba($blue,1.0);
//         color: white;
//         width: 320px;
//         margin: 0 auto;
//         text-align: center;
//         font-size: 1.2em;
//         border-radius: 5px;
//         cursor: pointer; 
//         margin-top: 80px;
//         margin-bottom: 50px;
//         transition: all .2s linear;
        
//         @media screen and (max-width: $break) {
//             margin: 0 auto;
//             margin-top: 60px;
//             margin-bottom: 50px;
//             width: 200px;
//         }
        
//         &:hover {
//             background-color: rgba($blue,0.8);
//             transition: all .2s linear;
//         }
//     }
// }


/********************Global Button css start**********************/

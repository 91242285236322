.custom-tooltip {
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .label {
    font-size: 15px;
    color: #225fa0;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  }
  .date {
    font-size: 12px;
    color: #000000;
    line-height: 1;
  }
}
.pulse_chart {
  &-wrapper {
    &.MuiPaper-root {
      box-shadow: 0px 2px 6px #00000029;
      border-radius: 10px;
      text-align: center;
      border: 1px solid #22A6DE;
    }
    .MuiCardContent-root {
      padding: 0 !important;
    }
  }
}
.recharts {
  &-wrapper {
    .recharts-xAxis {
      line {
        display: none;
      }
    }
    .recharts-yAxis {
      line {
        display: none;
      }
      .recharts-cartesian-axis-tick {
        &:first-child {
          display: none;
        }
      }
    }
    // .recharts-cartesian-axis-tick-line {
    //   display: none;
    // }
  }
}
.graphButtonRow{
  button{
    text-transform: capitalize;
    box-shadow: none;
    line-height: 1;
    font-size: 12px;
    padding: 10px 15px;
    @media (max-width: 1350px) {
      padding: 10px 10px;
    }
  }
}
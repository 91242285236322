.customPopup {
  z-index: 2000 !important;
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .MuiBox-root {
    max-height: calc(90vh - 120px);
    overflow: auto;
    padding-top: 1px;
    margin-bottom: 0;
    @media screen and (max-width: 1200px) {
      max-height: calc(100vh - 50px);
    }
    @media screen and (max-width: 576px) {
      max-height: calc(100vh - 40px);
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1c5492;
      border-radius: 10px;
    }
    .commentBoxWrap {
      margin-top: 20px;
      &:first-child {
        margin-top: 0px;
      }
      .commentTopInfo {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        @media screen and (max-width: 576px) {
          flex-direction: column;
          align-items: baseline;
        }
      }
    }
  }
  .popupHeader {
    padding: 23px 40px 23px 20px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 1400px) {
      padding: 20px 40px 20px 20px;
    }
    h4 {
      color: #000;
      font-size: 24px;
      margin: 0;
      font-weight: 400;
      @media screen and (max-width: 1400px) {
        font-size: 20px;
      }
    }
    button {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 40px;
      transition: all ease-in-out 0.3s;
      &:hover {
        transform: rotate(90deg);
      }
    }
  }
}
.info_popup {
  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .MuiIconButton-root {
      width: 40px;
      height: 40px;
      position: relative;
      right: 5px;
      top: 5px;
      width: 40px;
      transition: all ease-in-out 0.3s;
      &:hover {
        transform: rotate(90deg);
      }
    }
  }
  &-wrapper {
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .MuiCardContent-root {
      text-align: center;
      padding: 16px 50px;
      @media (max-width: 767px) {
        padding: 16px;
      }
      .svg-inline--fa {
        font-size: 65px;
        color: #225fa0;
      }
      .MuiTypography {
        &-h4 {
          margin: 10px 0;
          font-size: 32px;
          font-weight: bold;
          color: #225fa0;
          @media (max-width: 767px) {
            font-size: 28px;
          }
        }
        &-body1 {
          color: #212121;
          font-size: 22px;
          line-height: 1.3;
          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
    .MuiCardActions-root {
      padding: 10px 8px 30px;
      justify-content: center;
      .MuiBox-root {
        width: 100%;
        max-width: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiButtonBase-root {
          width: 100%;
          @media (max-width: 767px) {
            width: auto;
          }
        }
      }
    }
  }
}
.employee_comment {
  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .MuiIconButton-root {
      width: 40px;
      height: 40px;
      position: relative;
      right: 5px;
      top: 5px;
      width: 40px;
      transition: all ease-in-out 0.3s;
      &:hover {
        transform: rotate(90deg);
      }
    }
  }
  &-popup {
    .MuiCardContent-root {
      padding: 0 40px 25px;
      @media (max-width: 991px) {
        padding: 0 20px 25px;
      }
      .MuiTypography {
        &-h2 {
          font-size: 26px;
          font-weight: bold;
          color: #225fa0;
          margin-bottom: 5px;
          @media (max-width: 1400px) {
            font-size: 22px;
          }
          @media (max-width: 991px) {
            font-size: 20px;
          }
        }
        &-h6 {
          color: #212121;
          font-size: 20px;
          font-weight: normal;
          @media (max-width: 1400px) {
            font-size: 18px;
          }
          @media (max-width: 991px) {
            font-size: 16px;
          }
          span {
            font-weight: bold;
          }
        }
        &-body1 {
          font-size: 18px;
          color: #000;
          @media (max-width: 1400px) {
            font-size: 16px;
          }
          @media (max-width: 991px) {
            font-size: 14px;
          }
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
  &-contentHead {
    border-bottom: 1px solid #bbb;
    padding-bottom: 10px;
    margin-bottom: 14px;
  }
}

.pulse_rating {
  &-wrapper {
    &.MuiPaper-root {
      // min-width: 500px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      text-align: center;
      background: transparent
        linear-gradient(180deg, #22a6de 0%, #2283bf 32%, #225fa0 100%) 0% 0%
        no-repeat padding-box;
      border: 1px solid #22a6de;
      height: 100%;
      @media (max-width: 1199px) {
        height: auto;
      }
      @media (max-width: 991px) {
        min-width: 100%;
      }
    }
    &.MuiCard {
      &-root {
        .MuiCardHeader-root {
          padding: 26px 16px;
          background: #fff;
          color: #000;
          @media (max-width: 1400px) {
            padding: 20px 16px;
          }
          @media (max-width: 1200px) {
            padding: 15px 16px;
          }
          .MuiTypography-root {
            @media (max-width: 1400px) {
              font-size: 20px;
            }
            @media (max-width: 1200px) {
              font-size: 18px;
            }
          }
        }
        .MuiCardContent-root {
          .MuiTypography-root {
            font-size: 18px;
            color: #fff;
            font-style: italic;
            font-weight: bold;
            margin-bottom: 25px;
            @media (max-width: 991px) {
              font-size: 16px;
            }
            @media (max-width: 768px) {
              font-size: 15px;
            }
          }
          .MuiBox {
            &-root {
              .MuiFormGroup-root {
                max-width: 550px;
                margin: 0 auto;
                justify-content: space-between;
                @media (max-width: 576px) {
                  justify-content: center;
                  max-width: 230px;
                }
                .MuiFormControlLabel-root {
                  margin: 0;
                  flex-wrap: wrap;
                  flex-direction: column;
                  justify-content: center;
                  @media (max-width: 576px) {
                    padding: 0 5px;
                  }
                  .MuiRadio-root {
                    padding: 0;
                    filter: drop-shadow(0px 1px 6px #00000029);
                    &.Mui-checked {
                      &:before {
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        background-image: radial-gradient(
                          #ff7f00,
                          #ff7f00 28%,
                          transparent 32%
                        );
                        content: "";
                        @media (max-width: 1499px) {
                          width: 38px;
                          height: 38px;
                        }
                        @media (max-width: 767px) {
                          width: 33px;
                          height: 33px;
                        }
                      }
                    }
                    .MuiSvgIcon-root {
                      font-size: 40px;
                      color: #fff;
                      @media (max-width: 1499px) {
                        font-size: 30px;
                      }
                      @media (max-width: 991px) {
                        font-size: 30px;
                      }
                    }
                  }
                  .MuiFormControlLabel-label {
                    font-size: 17px;
                    display: block;
                    width: 100%;
                    font-style: normal;
                    margin-bottom: 10px;
                    @media (max-width: 1440px) {
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }
        .MuiCardActions-root {
          margin-top: 14px;
          padding: 10px 8px 30px;
          justify-content: center;
          position: relative;
          @media (max-width: 1499px) {
            margin-top: 0;
            padding: 0 8px 70px;
            flex-direction: column;
          }
          @media (max-width: 767px) {
            padding: 10px 8px 70px;
            flex-direction: column;
          }
          @media (max-width: 576px) {
            padding: 0px 8px 60px;
            flex-direction: column;
          }
          .MuiButton-root {
            &.Mui-disabled {
              opacity: 0.3;
              color: #fff;
              border-color: #fff;
            }
          }
          .checkIn-message {
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 15px;
            left: 25px;
            @media (max-width: 767px) {
              bottom: 15px;
              /* position: relative; */
              /* width: 100%; */
              /* align-items: flex-end; */
              margin-top: 10px;
              left: 15px;
            }
            .MuiTypography-root {
              font-size: 12px;
              color: #fff;
              font-style: italic;
              font-weight: normal;
              line-height: 1.2;
            }
          }
          .add-comment-btn {
            position: absolute;
            right: 5px;
            bottom: 5px;
            font-size: 14px;
            text-transform: capitalize;
            padding: 0;
            .btn-primary{
              font-weight: 400;
              padding: 10px 20px;
              min-width: auto;
              @media (max-width: 576px) {
                padding: 10px 10px;
                font-size: 12px;
              }
            }
            svg {
              margin-left: 5px;
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.notifications {
  &-wrapper {
    &.MuiPaper-root {
      // max-width: 875px;
      box-shadow: 0px 0px 6px #0000001a;
      border-radius: 8px;

      @media (max-width: 1199px) {
        max-width: 100%;
      }

      .MuiCardHeader {
        &-root {
          text-align: center;
          border-bottom: 1px solid #e2e2e2;

          .MuiTypography-root {
            font-size: 24px;

            @media (max-width: 1600px) {
              font-size: 20px;
            }

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }
      }

      .MuiCardContent-root {
        max-height: 752px;
        overflow: auto;
        @media (max-width: 1200px) {
          max-height: 552px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent
        }

        &::-webkit-scrollbar {
          width: 8px;
          background-color: transparent
        }

        &::-webkit-scrollbar-thumb {
          background-color: #E0E0E0;
          border-radius: 10px;
        }

        .MuiList-root {
          padding: 0;

          .MuiListItem-root {
            display: grid;
            grid-template-columns: 1fr 2.5fr;
            grid-gap: 10px;

            @media (max-width: 768px) {
              grid-gap: 0;
              grid-template-columns: 1fr;
              border: 1px solid #e2e2e2;
              border-radius: 4px;
              padding: 0;
            }

            &:not(:last-child) {
              @media (max-width: 768px) {
                margin-bottom: 10px;
              }
            }

            .notifications {
              &-user {
                flex-direction: row;
                align-items: center;
                max-width: 195px;
                min-width: 195px;

                @media (max-width: 768px) {
                  padding: 10px;
                }

                .MuiAvatar-root {
                  width: 50px;
                  height: 50px;
                  margin-right: 10px;
                  border: 4px solid #e6edf5;
                }

                .MuiListItemText {
                  &-primary {
                    font-size: 12px;
                    color: #000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  &-secondary {
                    font-size: 16px;
                    color: #707070;
                  }
                }
              }

              &-details {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                border-radius: 4px;
                padding: 12px 15px;
                background-color: #e6edf5;

                @media (max-width: 768px) {
                  border-radius: 0 0 4px 4px;
                }

                @media (max-width: 575px) {
                  padding: 10px;
                  flex-direction: column;
                }

                .MuiBox-root {
                  display: flex;
                  align-items: center;
                  // justify-content: space-between;
                  // flex: 1 1 100%;

                  @media (max-width: 768px) {
                    width: 100%;
                  }

                  &.details {
                    &-left {
                      position: relative;
                      padding-left: 20px;
                      
                      @media (max-width: 1499px) {
                        flex-wrap: wrap;
                        padding-left: 15px;
                      }

                      &:not(.low-score) {
                        @media (max-width: 1499px) {
                          padding-left: 0;
                        }
                      }

                      // &:not(.low-score) {
                      //   @media (max-width: 768px) {
                      //     padding-left: 0;
                      //   }
                      // }

                      .svg-inline--fa {
                        color: #ff3e3e;
                        position: absolute;
                        left: 0;
                        font-size: 30px;

                        @media (max-width: 768px) {
                          font-size: 22px;
                        }

                        // @media (max-width: 575px) {
                        //   left: 8px;
                        // }
                      }

                      .MuiTypography {
                        &-h6 {
                          margin-right: 10px;
                        }
                      }

                      .MuiButtonBase-root {
                        margin-right: 10px;
                        border-radius: 50px;
                        padding: 0;
                        text-transform: capitalize;
                        text-decoration: underline;
                        font-style: italic;
                        white-space: nowrap;
                      }
                    }

                    &-right {
                      justify-content: flex-end;
                      min-width: 80px;
                      @media (max-width: 575px) {
                        justify-content: flex-start;
                        margin-top: 5px;
                      }

                      .MuiTypography-root {
                        color: #000;
                        font-size: 18px;
                        font-weight: 700;
                        margin-right: 8px;
                        white-space: nowrap;

                        @media (max-width: 768px) {
                          font-size: 16px;
                        }
                      }

                      .MuiChip-root {
                        width: 37px;
                        height: 37px;
                        border-radius: 50px;
                        @media (max-width: 768px) {
                          width: 30px;
                          height: 30px;
                        }

                        .MuiChip-label {
                          font-size: 18px;
                          font-weight: bold;
                          padding: 0;

                          @media (max-width: 768px) {
                            font-size: 16px;
                          }
                        }

                        &.green {
                          background-color: #46a800;
                        }

                        &.red {
                          background-color: #ff3e3e;
                        }
                      }
                    }
                  }
                }

                .MuiTypography {
                  &-h6 {
                    font-size: 18px;
                    font-weight: bold;
                    white-space: nowrap;

                    @media (max-width: 768px) {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
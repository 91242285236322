.employee_card-wrapper {
  .profileInfoWrap {
    width: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 0;
    margin-bottom: 25px;
    border: 1px solid #22a6de;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 25px 16px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .MuiAvatar-root {
      width: 149px;
      height: 149px;
      padding: 5px;
      background: linear-gradient(180deg, #22a6de, #2283bf, #225fa0);
    }

    .MuiSlider-root {
      height: 15px;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 24px;
      max-width: 128px;
      background: transparent linear-gradient(90deg, #225FA0 0%, #22A1DA 50%, #22A6DE 100%) 0% 0% no-repeat padding-box;

      .MuiSlider-rail {
        opacity: 0;
      }

      .MuiSlider-track {
        opacity: 0;
      }

      .MuiSlider-thumb {
        background-color: #fff;
        border: 1px solid #225FA0;
        width: 22px;
        height: 22px;
      }

      .MuiSlider-valueLabel {
        padding: 0;
        background: transparent;
        top: 50%;
        transform: translateY(-50%);

        .MuiSlider-valueLabelLabel {
          color: #000;
          font-size: 14px;
          font-weight: 600;

        }
      }
    }
    .MuiButtonBase-root {
      color: #FF0000;
      text-transform: capitalize;
      border-radius: 50px;
      padding: 5px 15px;
      text-decoration: underline;
      svg {
        margin-left: 5px;
        fill: #FF0000;
      }
    }
    
  }

  .profileBtnsWrap {
    .MuiButtonBase-root {
      width: 100%;
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }
}